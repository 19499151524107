<template>
  <div>
    <br />
    <br />
    <div class="row">
      <div class="col-6">
        <label for="">Nom</label>
        <input type="text" v-model="expense.fullName" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Catégorie</label>
        <select name="" id="" class="form-select">
          <option value="">Dépenses de véhicules</option>
          <option value="">Charges locatives</option>
          <option value="">Frais d'eau et d'électricité</option>
          <option value="">Salaires</option>
        </select>
      </div>

      <div class="col-3">
        <label for="">Dépenses de véhicules</label>
        <select name="" id="" class="form-select">
          <option value="">Essence / diesel</option>
          <option value="">Assurance</option>
          <option value="">Vignette</option>
          <option value="">Visite technique</option>
        </select>
      </div>
    </div>
    <br />
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(expense)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expense: {},
    };
  },
  computed: {},
  methods: {
    async save(data) {
      await this.$store.dispatch("expense/store", data);
    },
    async empty() {
      this.$store.commit("expense/setExpense", {});
    },
  },
  beforeMount() {
    this.$store.commit("expense/setExpense", {});
  },
};
</script>
